import clsx from 'clsx';

import CloseButton from '../CloseButton';
import style from './style.scss';

/**
 * @typedef {object} HeaderProps
 * @property {(e: any) => void} [onClose]
 * @property {import('preact').ComponentChildren} [children]
 * @property {string} [className]
 */

/**
 * @type {import('preact').FunctionComponent<HeaderProps>}
 */
const Header = ({ onClose, children, className }) => (
  <div className={clsx(style.header, className)}>
    <CloseButton onClick={onClose} className={style.close} />
    {children}
  </div>
);

export default Header;
