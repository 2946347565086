import { faApple, faYahoo } from '@fortawesome/free-brands-svg-icons';
import { faCalendarPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { GoogleCalendar, ICalendar, OutlookCalendar, YahooCalendar } from 'datebook';
import { DropButton } from 'grommet';
import { useMemo } from 'preact/hooks';

import { iOSDevice } from '@/helpers';

import GoogleCalendarIcon from './GoogleCalendarIcon';
import OutlookCalendarIcon from './OutlookCalendarIcon';

const DROP_ALIGN = { top: 'top', right: 'right', left: 'left' } as const;

interface AddToCalendarButtonProps {
  description: string;
  title: string;
  startTime: string;
  stopTime: string;
  url: string;
}

const AddToCalendarButton = ({ description, title, startTime, stopTime, url }: AddToCalendarButtonProps) => {
  const isIOS = iOSDevice();
  const { icalLink, googleLink, outlookLink, yahooLink } = useMemo(() => {
    const event = {
      title,
      description: `${description}\n\n${url}`,
      location: url,
      start: new Date(startTime),
      end: new Date(stopTime),
    };
    const icalendar = new ICalendar(event)
      .addProperty('ORGANIZER', `CN=zoolife:mailto:${process.env.PREACT_APP_SUPPORT_EMAIL}`)
      .addAlarm({
        action: 'DISPLAY',
        description: `Reminder - ${title}`,
        trigger: {
          minutes: 10,
        },
      });
    const googleCalendar = new GoogleCalendar(event);
    const yahooCalendar = new YahooCalendar(event);
    const outlookCalendar = new OutlookCalendar(event);
    return {
      icalLink: icalendar.render(),
      googleLink: googleCalendar.render(),
      yahooLink: yahooCalendar.render(),
      outlookLink: outlookCalendar.render(),
    };
  }, [description, title, startTime, stopTime, url]);

  return (
    <DropButton
      label="Add to Calendar"
      primary
      className="min-h-[45px] w-full !text-[16px] hover:bg-[rgb(240_242_225)]"
      dropAlign={DROP_ALIGN}
      dropContent={
        <ul className="preflight preflight-ul grid grid-cols-1 text-primary-green">
          <li className={clsx('hover:bg-[rgb(240_242_225)]', !isIOS && 'order-last')}>
            <a
              download
              className="preflight preflight-a flex items-center p-4"
              href={`data:text/calendar;charset=utf8,${encodeURI(icalLink)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {isIOS ? (
                <>
                  <FontAwesomeIcon className="mr-2 inline-block h-4 w-4 text-black" icon={faApple} /> Apple
                </>
              ) : (
                <>
                  <FontAwesomeIcon className="mr-2 inline-block h-4 w-4" icon={faCalendarPlus} /> iCal File
                </>
              )}
            </a>
          </li>
          <li className="hover:bg-[rgb(240_242_225)]">
            <a
              className="preflight preflight-a flex items-center p-4"
              href={googleLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GoogleCalendarIcon className="mr-2 inline-block h-4 w-4" />
              Google
            </a>
          </li>
          <li className="hover:bg-[rgb(240_242_225)]">
            <a
              className="preflight preflight-a flex items-center p-4"
              href={outlookLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OutlookCalendarIcon className="mr-2 inline-block h-4 w-4" />
              Outlook
            </a>
          </li>
          <li className="hover:bg-[rgb(240_242_225)]">
            <a
              className="preflight preflight-a flex items-center p-4"
              href={yahooLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYahoo} className="mr-2 inline-block h-4 w-4 text-[color:#410093]" />
              Yahoo!
            </a>
          </li>
        </ul>
      }
    />
  );
};

export default AddToCalendarButton;
