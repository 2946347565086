import { Layer } from 'grommet';
import { h } from 'preact';

import StatusContent from './StatusContent';

const SuccessModal = ({ text, onClose, extraButtons }) => (
  <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
    <StatusContent
      showClose={false}
      type="success"
      title="Done!"
      text={text}
      onClose={onClose}
      extraButtons={extraButtons}
    />
  </Layer>
);

export default SuccessModal;
