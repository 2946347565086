import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseButton from 'Components/modals/CloseButton';
import { Box, Heading, Layer, Text } from 'grommet';

import style from './style.scss';

/**
 * Renders an ActionModal component.
 *
 * @param {object} props - The props object.
 * @param {import('preact/compat').ReactNode} [props.img] - The image to be displayed in the header of the modal.
 * @param {import('@fortawesome/fontawesome-svg-core').IconDefinition | null} [props.icon] - The icon to be displayed in the header of the modal. Defaults to faBell.
 * @param {string} props.title - The title of the modal.
 * @param {import('preact/compat').ReactNode} [props.subTitle] - The subtitle of the modal.
 * @param {function} [props.onClose] - The function to be called when the modal is closed.
 * @param {import('preact/compat').ReactNode} props.primaryButton - The primary button component.
 * @param {import('preact/compat').ReactNode} props.secondaryButton - The secondary button component.
 * @returns {import('preact').JSX.Element} The rendered ActionModal component.
 */
const ActionModal = ({ img, icon = faBell, title, subTitle, onClose, primaryButton, secondaryButton }) => (
  <Layer className={style.layer} onEsc={onClose} onClickOutside={onClose}>
    <div className={style.container}>
      {img && (
        <div className={style.header}>
          {img}
          <CloseButton varient="grey" onClick={onClose} className={style.closeBtn} />
        </div>
      )}
      <Box className={style.contentWrapper}>
        <Heading level="3" textAlign="center" className={style.headingText}>
          {icon && <FontAwesomeIcon icon={icon} className={style.bellIcon} />}
          {title}
        </Heading>
        <Text size="large" textAlign="center" className={style.description}>
          {subTitle}
        </Text>
      </Box>
      <Box pad="25px" align="center">
        <div className={style.buttonsContainer}>
          <div>{primaryButton}</div>
          <div>{secondaryButton}</div>
        </div>
      </Box>
    </div>
  </Layer>
);

export default ActionModal;
