import { NewOutlineButton, OutlineButton, PrimaryButton } from 'Components/Buttons';
import { useState } from 'preact/hooks';
import useFetch from 'use-http';

import ActionModal from '@/components/ActionModal';
import LoaderModal from '@/components/LoaderModal';
import ErrorModal from '@/components/modals/Error';
import SuccessModal from '@/components/modals/Success';
import { buildURL } from '@/shared/fetch';
import { logGAEvent } from '@/shared/ga';

import { notificationPermissionStatus, requestNotificationPermission } from '../../helpers';
import AddToCalendarButton from './AddToCalendarButton';

const TalkReminderModal = ({
  id,
  eventTitle,
  eventDescription,
  startTime,
  stopTime,
  animal,
  url,
  businessHourId,
  onClose,
}) => {
  const [error, setError] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const {
    post,
    response: reminderResponse,
    loading: sendingReminder,
  } = useFetch(buildURL(), { credentials: 'include', cachePolicy: 'no-cache' });

  const logUserInteraction = (action) => {
    logGAEvent(action, animal, businessHourId);
  };

  const onAllow = async () => {
    const permissionState = await notificationPermissionStatus();
    const granted = permissionState === 'granted';
    const denied = permissionState === 'denied';
    if (!granted && !denied) {
      requestNotificationPermission();
    }
    await post('/schedules/reminder', { scheduleId: id });
    if (reminderResponse.ok) {
      setShowSuccessModal(true);
    } else {
      setError('Something went wrong. Please try again');
    }
    logUserInteraction('Click-Scheduled-Talk-Reminder');
  };

  return (
    <>
      {!error && !showSuccessModal && (
        <ActionModal
          onClose={onClose}
          title="Set Event Reminder"
          subTitle="Want to try to join us for this event?"
          primaryButton={<PrimaryButton size="large" label="Remind Me" onClick={onAllow} className="w-full" />}
          secondaryButton={<NewOutlineButton size="large" label="Never Mind" onClick={onClose} className="w-full" />}
        />
      )}
      {sendingReminder && <LoaderModal />}
      {error && <ErrorModal text={error} onClose={onClose} />}
      {showSuccessModal && (
        <SuccessModal
          text="You will receive a notification on this device when the event starts. We also emailed you a calendar invite with all the details!"
          onClose={onClose}
          extraButtons={
            <>
              <div className="mt-6">
                <AddToCalendarButton
                  title={eventTitle}
                  description={eventDescription}
                  startTime={startTime}
                  stopTime={stopTime}
                  url={url}
                />
              </div>
              <div className="mt-6">
                <OutlineButton size="large" label="Close" onClick={onClose} />
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default TalkReminderModal;
